

.App-intro {
  font-size: large;
}
.App-Title {
    font-size: 1.5em;
    color: #61dafb;
}

.App-header {
  display:block;
  background-color: #222;
  padding: 20px;
  color: white;
  text-align: center;
}
.App-logo {

  animation: spin infinite 20s linear;
  height: 80px;
}


@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
